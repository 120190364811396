.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 50px 0px;
}

.card {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
    background-color: rgba(20, 20, 20, 1);
}

.introImage {
    width: 250px;
    height: 250px;
    font-size: 250px;
    object-fit: contain;
}

.content { 
    max-width: 800px;
    padding: 0px 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
}

.title {
    font-size: 36px;
}

.subTitle {
    font-size: 22px;
    font-style: italic;
}

.desc {
    padding: 20px;
}

.contentImage {
    align-self: center;
    max-width: 80%;
    max-height: 600px;
    object-fit: contain;
}

.list {
    align-self: flex-start;
    justify-self: flex-start;
    margin-top: 20px;
}

.list ul {
    margin-top: 2px;
}