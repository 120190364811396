html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(40, 40, 40, 1);
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: whitesmoke;
}

hr {
  border-color: #35B4F1;
  border-bottom-width: 3px;
  width: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 0;
  overflow: visible;
}

.button {
  cursor: pointer;

  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;

  border-radius: 5px;
  text-decoration: none;
  font-weight: 400;
  padding: 10px 15px;
}